$(document).ready(function () {
  let header = $("header");

  $(window).scroll(function () {
    if ($(this).scrollTop() > 10) {
      header.css("background-color", "#000");
    } else {
      header.css("background-color", "transparent");
    }
  });

  $(".mobile-open").click(function () {
    if ($(".header-menus").is(":visible")) {
      $(".header-menus").hide();

      if ($(window).scrollTop() > 10) {
        header.css("background-color", "#000");
      } else {
        header.css("background-color", "transparent");
      }
    } else {
      $(".header-menus").css("display", "flex");
      header.css("background-color", "#000");
    }
  });

  $(".discover").click(function () {
    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $("#discover").offset().top,
        },
        500,
        "swing"
      );
  });

  $(".schedule").click(function () {
    $("html, body")
      .stop()
      .animate(
        {
          scrollTop: $("#fla-form").offset().top,
        },
        500,
        "swing"
      );
  });

  $("#send-btn").click(function (e) {
    e.preventDefault();

    const name = $("#form-name").val();
    const organization = $("#form-organization").val();
    const phone = $("#form-phone").val();
    const email = $("#form-email").val();
    const note = $("#form-note").val();

    const data = {
      name,
      organization,
      email,
      phone,
      note
    };

    $.ajax({
      type: "POST",
      url: "/api/send-email.php", // Substitua pelo caminho real do seu script PHP
      contentType: "application/json; charset=utf-8",
      dataType: "json",
      data: JSON.stringify(data),
      beforeSend: function() {
        $("#form-contact").hide()
        $(".form-feedback").hide()
        $("#spinner-form").show()
      },
      success: function(response) {
        $("#form-contact").show()
        $("#form-success").show()
        $("#spinner-form").hide()
      },
      error: function(error) {
        $("#form-contact").show()
        $("#form-fail").show()
        $("#spinner-form").hide()
      }
    });

  }) 
});
